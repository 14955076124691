@font-face {
  font-family: "Blocky";
  src: url(./fonts/blocky.ttf);
}


$Outfit: 'Outfit Variable', 'Arial', sans-serif;
$darkBackground: #333;

html, body {
  height: 100%;
  width: 100%;
  font-family: var(--Outfit);
}

.scene-background {
  background-image: url(./images/map_scene1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

* {
  font-family: var(--Outfit);
}

.brandTitle {
  width: 35vh;
  image-rendering: pixelated;
}

.footer {
  font-family: var(--Outfit);
}